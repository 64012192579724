import React from 'react';
import styled from 'styled-components';

import Layout from '../layout/layout';
import SEO from '../components/seo';

const StyledIndex = styled.main`
  p {
    font-family: 'Special Elite', sans-serif;
    line-height: 3.5rem;
    font-size: 2.5rem;
    padding: 1rem 0;
    margin: 0;
  }

  @media (max-width: ${({ theme }) => theme.mobile}) {
    p {
      line-height: 3rem;
      font-size: 2rem;
      padding: 1rem 0 0 0;
    }
  }
`;

const Index = () => (
  <Layout>
    <SEO title='Home' keywords={['demonsgalore', 'web developer', 'web development', 'Webentwicklung', 'WEbentwickler', 'React', 'JavaScript']} />
    <StyledIndex>
      <p>Hello. My name is <span>Andreas</span> Stopat.</p>
      <p>I'm a full stack <span>web developer</span>.</p>
      <p>Currently developing <span>web applications</span> and <span>websites
      </span> with <span className="title-highlight">react</span>.
      </p>
    </StyledIndex>
  </Layout>
);

export default Index;
